import {BooleanField, FunctionField, List, TextField, usePermissions} from "react-admin";
import ConfigurableDatagrid from "../../components/ConfigurableDatagrid";
import {getValidPrivilegesIds} from "../../components/authentication/PermissionHandler";
import {EmptyPage} from "../../components/EmtpyPage";

export const BusinessUnitList = () => {
    const { permissions } = usePermissions()
    const hasEdit = getValidPrivilegesIds("BUSINESS_UNIT", "EDIT").some(r => permissions.includes(r)) ? "edit" : undefined
    return(
    <List title={"businessUnits.title"} empty={<EmptyPage text={"businessUnits.empty"} createEnabled={true} />}>
        <ConfigurableDatagrid rowClick={hasEdit} bulkActionButtons={false} >
            <TextField source="id" label={'businessUnits.id'} />
            <TextField source="name" label={'businessUnits.name'} />
            <TextField source="company" label={'businessUnits.company'} />
            <TextField source="zipCode" label={'businessUnits.zipCode'} />
            <TextField source="settlement" label={'businessUnits.settlement'} />
            <FunctionField render={
                record => record.fullAddress ? record.fullAddress : record.address
            } label={"businessUnits.address"}/>
            <TextField source="ntakRegNumber" label={'businessUnits.ntakNumber'} />
            <BooleanField source="simplifiedFlag" label={'businessUnits.simplifiedFlag'} />
        </ConfigurableDatagrid>
    </List>
    )
}